import Swipe from 'swipejs'

var animationLength = 500; // Change this in _grensesnitt-lightbox.scss as well if changed

var $grensesnittLightboxContainer = $('.gl-container');
var $lightboxes = $('.lightbox img');
var $navPrev = $('.gl-nav-prev > a');
var $navNext = $('.gl-nav-next > a');
var swipe;
var trigger = $('.project');
var noMobileScroll;
var arrows = $('.arrow');
var popupWrapper = $('.popup-wrapper');
let popupActive = false;
var newsletterTrigger = $('a[href*="#newsletter"]');

var hideNewsletterKey = 'hideNewsletter';

function setCookie() {
    var expires = new Date();
    expires.setMonth(expires.getMonth() + 12);
    document.cookie = hideNewsletterKey + '=1; expires=' + expires + '; path=/';
}


    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

newsletterTrigger.click(function(){
    showNewsletter()
})
if(window.location.hash === '#newsletter'){
    showNewsletter()
}
if(popupWrapper.data('active') === 1) {
    if(getCookie(hideNewsletterKey)){
    } else {
        setTimeout(function () {
            showNewsletter()
            setCookie()
        }, 30000)
    }
}


$lightboxes.each(function (index, el) {
    var $el = $(el);
    $el.attr('data-index', index);
});

$lightboxes.click(function(e){
    e.preventDefault();
    popupActive = false;
    showLightbox();
    if (swipe) {
        swipe.kill();
        swipe = null;
    }
    addImages(this)
    swipe = new Swipe($('.gl-swipe')[0], {
        draggable: true,
        continuous: true,
        disableScroll: true
    });

    $grensesnittLightboxContainer[0].addEventListener('touchmove', function (e) {
        e.preventDefault();
    });

    var index = parseInt($(this).data('index')) || 0;
    swipe.slide(index, 1);
})

$('.gl-container .gl-close').click(function (e) {
    e.preventDefault();

    hideLightbox();
});

$navNext.click(function (e) {
    e.preventDefault();

    swipe.next();
});

$navPrev.click(function (e) {
    e.preventDefault();

    swipe.prev();
});

$('.gl-container').click(function (e) {
    var hasParent = $(e.target).parents().hasClass('popup-wrapper');
    if(hasParent){
        return;
    }else if($(e.target).hasClass('no-close')){
        return;
    } else if(e.target.className === 'arrow'){
        return;
    }
    switch(e.target.tagName){
        case 'IMG':
            return;
        case 'svg':
            return;
        case 'A':
            return;
    }
    hideLightbox();
});

function addImages(current) {
    var $swipeWrap = $('.gl-swipe-wrap');
    var finder = $(current).find('img');
    if(current.tagName === 'IMG'){
        $swipeWrap.html('');
        $lightboxes.each(function (index, el) {
            var url = el.src;
            $swipeWrap.append('<div class="swipe-img"><img src="' + url + '" /></div>')
        })
    }else {
        finder.each(function (index, el) {
            var url = el.src;
            var altText = el.alt

            $swipeWrap.append('<div class="swipe-img"><figure><img alt="' + altText + '" src="' + url + '" /></figure><div class="text-parent"><p class="img-text" id="text-' + index + '"></p><div class="text-wrapper"><p class="progress" id="progress-' + index + '"></p><p class="length"></p></div></div></div>')
            $('#text-' + index).html(altText);
            $('#progress-' + index).html(1 + index + '/');
        })
        $('.length').html(finder.length);
    }
}

function hideLightbox() {
    if($('.swipe-img')){
        $('.swipe-img').remove()
    }
    $('.arrow').show()
    popupWrapper.hide()

    $grensesnittLightboxContainer.removeClass('fade');

    setTimeout(function () {
        $grensesnittLightboxContainer.removeClass('show');
        $('body').removeClass('noscroll');
    }, animationLength);
}

function showLightbox(popup) {
    if(popup === true){
        $('.arrow').hide()
    }
    $grensesnittLightboxContainer.addClass('show');

    setTimeout(function () {
        $grensesnittLightboxContainer.addClass('fade');

        setTimeout(function () {
            $('body').addClass('noscroll');
        }, animationLength);
    }, 10);
}

function showNewsletter (){
    popupActive = true;
    popupWrapper.css('display', 'flex');
    showLightbox(popupActive) ;
}
